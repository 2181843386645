import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./AdmissionForm.css";
import CustomAlert from "./CustomAlert";
import { Link } from "react-router-dom";

const AdmissionForm = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Message: "",
    Mobile: "",
  });

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true); 

    const serviceID = "service_2veqkmd"; // Replace with your EmailJS service ID
    const templateID = "template_8ii2m3b"; // Replace with your EmailJS template ID
    const publicKey = "lSod9Ho16fFszMT24"; // Your Public Key

    emailjs.send(
      serviceID,
      templateID,
      {
        from_name: formData.Name,
        reply_to: formData.Email,
        message: `Name: ${formData.Name}\nMobile: ${formData.Mobile}\nMessage: ${formData.Message}`, // Simplified message
      },
      publicKey
    )
    .then((response) => {
      console.log("SUCCESS!", response.status, response.text);
      setAlertMessage("Your email has reached us. Our coordinator will contact you shortly.");
      setShowAlert(true);
    })
    .catch((error) => {
      console.error("FAILED...", error);
      setAlertMessage("There was an issue sending your message. Please try again.");
      setShowAlert(true);
    })
    .finally(() => {
      setIsLoading(false); // Set loading to false after the email is sent or an error occurs
    });
  };

  return (
   

<div>
<div className="admissionformsection01 row">
      <div className="col-md-7 col-xs-12">
        <div className="meta-section row ">
          <div className="col-md-8 col-xs-12 " style={{ margin: "auto", alignItems: "center", justifyContent: "center" }}>
            <h2 style={{ color: "#ff09c2" }}>
              "<span style={{ color: '#0d2369' }}>Empower</span> Your Journey, Shape the Future – Join Hands with Aekaksh
              School in Hyderabad. Special Offers for <span style={{ color: '#0d2369' }}>Women Entrepreneurs</span> Ready
              to Make a Big Impact!{" "}
              <span style={{ color: "#0d2369" }}>
                Call for Franchises Now – Be a Part of Our Growing <span style={{ color: '#ff09c2' }}>Success!</span>"
              </span>
            </h2>
          </div>
          <div className="col-md-4 mobhide">
            <div className="landing-img-wrapper">
              <img src="./images/adm-yellow-grl.png" alt="yellow-grl" />
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-5 admissionformsection-sec-2 ">
        <div className="commonform ">
          <div
            style={{
              position: "fixed",
              zIndex: 9999,
              inset: "16px",
              pointerEvents: "none",
            }}></div>
          <div className="form-card col-md-12">
            <h3>Enquiry Now</h3>
            <form method="post" role="form" encType="multipart/form-data" onSubmit={handleSubmit}>
              <div className="form-content" id="common-form-1">
                <input
                  className="form-input-box"
                  name="Name"
                  placeholder="Full Name"
                  type="text"
                  value={formData.Name}
                  onChange={handleChange}
                  required
                />
                <input
                  className="form-input-box"
                  name="Email"
                  placeholder="Email Id"
                  type="email"
                  value={formData.Email}
                  onChange={handleChange}
                  required
                />
                <input
                  className="form-input-box"
                  name="Mobile"
                  placeholder="Contact Number"
                  type="tel"
                  value={formData.Mobile}
                  onChange={handleChange}
                  required
                />
                <textarea
                  className="form-input-box"
                  name="Message"
                  placeholder="Your Message"
                  rows="4"
                  value={formData.Message}
                  onChange={handleChange}
                  required
                />
                <div className="check-box-row">
                  <label>
                    <input
                      required
                      type="checkbox"
                      className="form-check-box"
                      defaultChecked
                    />
                  </label>
                  <div>
                    <p className="tick-mark-form">
                      By ticking on the box and submitting this form, you agree
                      to the processing of your personal data in accordance with
                      the{" "}
                      <Link to='/privacy-policy' style={{ color: "#ff09c2" }}>
                        Privacy Policy
                      </Link>
                      .
                    </p>
                  </div>
                </div>
                <div className="form-button-container">
  <button type="submit" className="form-button" disabled={isLoading}>
    {isLoading ? (
      <div className="loader"></div> // Loader will appear here when loading
    ) : (
      "Submit"
    )}
  </button>
</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

{showAlert && (
  <CustomAlert 
    message={alertMessage}
    onClose={() => setShowAlert(false)}
  />
)}
</div>


  );
};

export default AdmissionForm;
