import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';

const FeeStructure = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if authToken exists in localStorage
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      // Redirect to login if the user is not authenticated
      navigate('/login');
    }
  }, [navigate]);
  
  return (
    <>

    <Navbar />
    
    <div className="fee-structure-container">
      <h1>Fee Structure for the 2025-26 Academic Year</h1>
      <p>Dear Franchise,</p>
      <p>
        We are sharing the fee structure guidelines for the 2025-26 academic year. Please find
        the details below:
      </p>
      <ul>
        <li><strong>Tuition Fees:</strong> As per the norms set by the franchisor.</li>
        <li><strong>Other Fee Heads:</strong> You may decide and finalize these based on your requirements and share the details with us for review.</li>
      </ul>
      <p>Feel free to reach out if you need any clarification or support while structuring the fees.</p>
      <h2>Additional Fees</h2>
      <ul>
        <li>Registration Fee</li>
        <li>Caution Deposit</li>
        <li>Admission Fee</li>
        <li>Annual Fee</li>
      </ul>
      <p>For any questions or further information, feel free to contact us.</p>
      <p>Best regards,</p>

      <Link to="/FeeStructure2025.pdf" target="_blank" download className="download-button">
        Download Fee Structure PDF
      </Link>
    </div>
    
    </>


  );
};

export default FeeStructure;