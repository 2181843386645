import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaWhatsappSquare,
  FaYoutubeSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="foot">
        {/* <div className='nav-logo'>
    <Link to='/' >
    <img src='./images/Aekaksh Logo R.png' />
    </Link>
    </div> */}
        <div className="footer-mainbox">
          <p>
            <Link to="/contact">Contact</Link>
          </p>
          <p>
            <Link to="/newsblogs">News Blogs</Link>
          </p>
          <p>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
          <p>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </p>
          <p>
            <Link to="/refund-policy">Refund Policy</Link>
          </p>
        </div>

        <div>
          <div className="footer-box3" xs={12} sm={12} md={3} lg={3} xl={3}>
            <h2 style={{ color: "#fff" }}>Follow Us</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href="https://www.instagram.com/aekakshfranchise?igsh=MWdlY2kxd2o1cmp1aw=="
                target="_blank"
                rel="noopener noreferrer">
                <FaInstagramSquare
                  style={{ fontSize: 30, color: "#fff", margin: "0 10px" }}
                />
              </a>

              <a
                href="https://www.facebook.com/share/45Jd2rFczWcabMYH/?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer">
                <FaFacebookSquare
                  style={{ fontSize: 30, color: "#fff", margin: "0 10px" }}
                />
              </a>

              <a
                href="https://www.aekakshschool.com/#"
                target="_blank"
                rel="noopener noreferrer">
                <FaYoutubeSquare
                  style={{ fontSize: 30, color: "#fff", margin: "0 10px" }}
                />
              </a>

              <a
                href="https://api.whatsapp.com/send?phone=9281042708"
                target="_blank"
                rel="noopener noreferrer">
                <FaWhatsappSquare
                  style={{ fontSize: 30, color: "#fff", margin: "0 10px" }}
                />
              </a>

              <a
                href="https://twitter.com/learnorama_in"
                target="_blank"
                rel="noopener noreferrer">
                <FaTwitterSquare
                  style={{ fontSize: 30, color: "#fff", margin: "0 10px" }}
                />
              </a>
            </div>
          </div>
        </div>

        <p>
          ©Designed and developed by{" "}
          <a href="https://learnorama.in/" style={{ color: "#fff" }}>
            Learn orama technologies Pvt Ltd.
          </a>{" "}
          All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
