import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';


const RegistrationForm = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if authToken exists in localStorage
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      // Redirect to login if the user is not authenticated
      navigate('/login');
    }
  }, [navigate]);

  return (
    <>

    <Navbar />
    
    
    <div className="fee-structure-container">
      <h3 className="small-heading">Aekaksh School Registration Form</h3>
      <p className="description">
        Download the Aekaksh School Registration Form to get started with the enrollment process. Fill in all the required details and submit it to complete your registration.
      </p>
      <Link to="/Aekaksh_School_Registration_Form.pdf" target="_blank" download className="download-button">
        Download Registration Form PDF
      </Link>
    </div>
    
    </>
  );
};

export default RegistrationForm;
