import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';

const AekakshSchoolPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if authToken exists in localStorage
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      // Redirect to login if the user is not authenticated
      navigate('/login');
    }
  }, [navigate]);

  return (
    <>
      <Navbar />
      
      <div className="fee-structure-container">
        <h3 className="small-heading">Aekaksh School Policy</h3>
        
        <div className="policy-section">
          <h4>➢ School Structure</h4>
          <p>
            The structure of Aekaksh School is designed to provide a comprehensive and inclusive educational environment, focusing on the development of students in various domains.
          </p>
        </div>

        <div className="policy-section">
          <h4>➢ Roles and Responsibilities</h4>
          <p>
            At Aekaksh School, roles and responsibilities are clearly defined for each individual in the institution, ensuring that all staff, students, and parents contribute to a positive and effective learning atmosphere.
          </p>
        </div>

        <Link to="/Aekaksh_School_Policy.pdf" target="_blank" download className="download-button">
          Download Policy PDF
        </Link>
      </div>
      
    </>
  );
};

export default AekakshSchoolPolicy;
