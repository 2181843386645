import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if authToken exists in localStorage
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      // Redirect to login if the user is not authenticated
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userEmail');
    navigate('/login');
  };

  return (
    <>
      <Navbar />
      
      <div className="dashboard-container">
        <h1>Welcome to the Dashboard</h1>
        <p>Select a document to view:</p>
        <div className="button-container">
          <Link to="/fee-structure" className="dashboard-button">Fee Structure for 2025-26</Link>
          <Link to="/school-interiors" className="dashboard-button">School Interiors</Link>
          <Link to="/registration-form" className="dashboard-button">Registration Form</Link>
          <Link to="/school-policy" className="dashboard-button">Aekash School Policy</Link>
        </div>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
    </>
  );
};

export default Dashboard;
