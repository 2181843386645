// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './components/Home';
// import PrivacyPolicy from './tc/PrivacyPolicy';
// import TermsAndConditions from './tc/TermsAndConditions';
// import RefundPolicy from './tc/RefundPolicy';
// import Footer from './tc/Footer';


// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <Routes>
//           <Route path="/" element={<Home />} />
//           {/* <Route path="/business-proposal" element={<BusinessProposal />} /> */}
//           <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//           <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
//           <Route path="/refund-policy" element={<RefundPolicy />} />
//         </Routes>
//         <Footer />
//       </div>
//     </Router>
//   );
// }

// export default App;


import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import necessary components
import Home from './components/Home';
import PrivacyPolicy from './tc/PrivacyPolicy';
import TermsAndConditions from './tc/TermsAndConditions';
import RefundPolicy from './tc/RefundPolicy';
import NewsBlogs from './tc/NewsBlogs';
import FloatingCallButton from './FloatingCallButton/FloatingCallButton';
import ContactPage from './components/ContactPage';
import Login from './Auth/Login';
import Dashboard from './Auth/Dashboard';
import FeeStructure from './Auth/PDFS/FeeStructure';
import SchoolInteriors from './Auth/PDFS/SchoolInteriors';
import RegistrationForm from './Auth/PDFS/RegistrationForm';
import AekakshSchoolPolicy from './Auth/PDFS/AekakshSchoolPolicy';
// import Footer from './tc/Footer';

function App() {
  return (
    <React.Fragment>
    <Routes>
    <Route path="/" element={<Home />} />
          <Route path="/newsblogs" element={<NewsBlogs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/contact" element={<ContactPage />} />

          {/* -------------------- */}

          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/fee-structure" element={<FeeStructure />} />
          <Route path="/school-interiors" element={<SchoolInteriors />} />
          <Route path="/registration-form" element={<RegistrationForm />} />
          <Route path="/school-policy" element={<AekakshSchoolPolicy />} />
          
        </Routes>
        <FloatingCallButton />
    </React.Fragment>
  );
}

export default App;
