import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';

const SchoolInteriors = () => {

  const navigate = useNavigate();
  
    useEffect(() => {
      // Check if authToken exists in localStorage
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        // Redirect to login if the user is not authenticated
        navigate('/login');
      }
    }, [navigate]);

    
  return (
    <>
    <Navbar />
    
    
    <div className="fee-structure-container">
      <h3 className="small-heading">School Interiors</h3>
      <p className="description">
        Explore our beautifully designed school interiors that foster creativity, comfort, and a positive learning environment.
      </p>
      <Link to="/School Interiors.pdf" target="_blank" download className="download-button">
        Download School Interiors PDF
      </Link>
    </div>
    
    </>



  );
};

export default SchoolInteriors;
