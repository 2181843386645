import React from "react";
import { Link } from "react-router-dom";
import { IoMdLogIn } from "react-icons/io"; // Importing the login icon
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-center">
        <Link to="/"><img src="./images/Aekaksh Logo R.png" alt="Logo" className="Nav-logo" /></Link>
      </div>
      <div className="navbar-right">
        <Link to="/login">
          <button className="login-btn">
            <span className="login-text">Login</span>
            <IoMdLogIn className="login-icon" /> {/* Using the IoMdLogIn icon */}
          </button>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
