import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Auth.css';
import axios from 'axios';
import CustomAlert from '../components/CustomAlert';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://aekakshfranchisebackend.shivagangagroup.com/api/auth/login', {
        email,
        password
      });
      const { token, user } = response.data;
      localStorage.setItem('authToken', token);
      localStorage.setItem('userEmail', user.email);
      setAlertMessage('Login Successful!');
      setShowAlert(true); // Show the alert
      setTimeout(() => navigate('/dashboard'), 1500); // Navigate after alert
    } catch (error) {
      setAlertMessage('Invalid Email or Password');
      setShowAlert(true); // Show the alert
      console.error('Login error:', error);
    }
  };

  const closeAlert = () => {
    setShowAlert(false); // Hide the alert when the close button is clicked
  };

  return (
    <div className="login-container">
      {showAlert && <CustomAlert message={alertMessage} onClose={closeAlert} />} {/* Render alert if showAlert is true */}
      
      <div className="logo-container">
        <Link to='/'><img src="./images/Aekaksh Logo R.png" alt="Logo" className="login-logo" /></Link>
      </div>
      <h2>Login Page</h2>
      <form onSubmit={handleLogin} className="login-form">
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
};

export default Login;
